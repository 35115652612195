<script setup lang="ts">
import { ListingFilter } from '@shopware-pwa/types';
// import SwFilterPropertiesVue from './listing-filters/SwFilterProperties.vue';
// import SwFilterPriceVue from './listing-filters/SwFilterPrice.vue';
// import SwFilterRatingVue from './listing-filters/SwFilterRating.vue';
// import SwFilterShippingFreeVue from './listing-filters/SwFilterShippingFree.vue';

import SwFilterPropertiesVue from './listing-filters/ListingFiltersProperties.vue';
import SwFilterPriceVue from './listing-filters/ListingFiltersPrice.vue';
import SwFilterRatingVue from './listing-filters/ListingFiltersRating.vue';
import SwFilterShippingFreeVue from './listing-filters/ListingFiltersShippingFree.vue';

const emit = defineEmits<{
  (e: 'selectFilterValue', { code, value }: { code: any; value: any }): void;
}>();

const props = defineProps<{
  filter: ListingFilter;
  selectedFilters: {
    [key: string]: any;
  };
}>();

// show only the options that has the flag st_region_filter_property_option_resort_visible = false
const filteredOptions = props.filter;
filteredOptions.options = filteredOptions?.options?.filter((option) => {
  return (
    option.translated?.customFields
      ?.st_region_filter_property_option_resort_visible !== true
  );
});

const cmsMap = () => {
  const map: {
    [key: string]: any;
  } = {
    manufacturer: SwFilterPropertiesVue,
    properties: SwFilterPropertiesVue,
    price: SwFilterPriceVue,
    rating: SwFilterRatingVue,
    'shipping-free': SwFilterShippingFreeVue,
  };

  return map[props.filter?.code];
};
</script>
<template>
  <div>
    <component
      :is="cmsMap()"
      :filter="filteredOptions"
      :selected-filters="selectedFilters"
      @select-value="emit('selectFilterValue', $event)"
    />
  </div>
</template>
